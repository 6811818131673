import React from 'react'
import styles from './support.module.sass'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Support from '../components/support'

const methods = ['faq', 'chat', 'phone', 'email', 'linkedin', 'directions']

const SupportPage = () => (
   <Layout title="Support Center">
      <SEO title="Support Center" />
      <div className={['page', styles.page__support].join(' ')}>
         <Support methods={methods} />
      </div>
   </Layout>
)

export default SupportPage
